import {
  Text,
  HStack,
  Heading,
  Center,
  Stack,
  Container,
  Box,
  SimpleGrid,
  Flex,
  Img,
} from "@chakra-ui/react";
import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Link as Navs } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { MdSecurity } from "react-icons/md";
import { Footer } from "../../components/landingpage/footer";
import Signin from "../signin/signin";
import { conf, sessionGetActiveConf } from "../../algorand/config";
import { SessionWallet } from "algorand-session-wallet";

const Landingpage = () => {
  const activeConf = sessionGetActiveConf();
  const sw = new SessionWallet(conf[activeConf].network);
  const [sessionWallet, setSessionWallet] = useState(sw);
  const [accts, setAccounts] = useState(sw.accountList());
  const [connected, setConnected] = useState(sw.connected());

  function updateWallet(sw: SessionWallet) {
    setSessionWallet(sw);
    setAccounts(sw.accountList());
    setConnected(sw.connected());
    window.location.replace("/dashboardhome");
  }

  return (
    <>

      <Stack
        // direction={{ base: "column", md: "row" }}
        // align="center"
        justify="center"
        // spacing={{ base: 4, md: 10 }}
        p={{ base: 4, md: "10em" }}
      >
        <Flex alignItems="center" justifyContent={"center"}>
          <Heading
            width="10em"
            lineHeight={1.1}
            fontWeight={700}
            fontSize={{ base: "3xl", sm: "4xl", lg: "7xl" }}
            pb={-100}
          >
            <Text
              as="span"
              position="relative"
              _after={{
                position: "absolute",
                bottom: 1,
                left: 0,
              }}
            >
              THIS IS
            </Text>
          </Heading>
          <Text fontSize="2xl" fontWeight="normal">
            Africerts is a premier platform for securely generating and storing
            academic certificates on the blockchain.
          </Text>
        </Flex>
        <Heading
          lineHeight={1.1}
          fontWeight={700}
          fontSize={{ base: "3xl", sm: "4xl", lg: "7xl" }}
        >
          <Text>THE FUTURE.</Text>
        </Heading>
        <Box>
          <Img src="./certificate.svg" />
        </Box>

        <Stack
          direction={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          spacing={{ base: 4, md: 10 }}
          pt={"isSmall" ? 20 : ""}
        >
          <Stack spacing={{ base: 5, md: 10 }} maxW="30em" pb={20}>
            <Heading
              lineHeight={1.1}
              fontSize={{ base: "3xl", sm: "4xl", lg: "4xl" }}
            >
              <Text
                as="span"
                position="relative"
                _after={{
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                }}
              >
                Secure , easy and
                <br /> universal access .
              </Text>
            </Heading>
            <Text fontSize="1em" fontWeight="large">
              AfriCerts provides a secure and transparent platform to generate,
              store, and verify academic certificates on the blockchain.
            </Text>
          </Stack>
          <Stack spacing={{ base: 5, md: 10 }} alignSelf="flex-end">
            <Box position="relative" w="full" overflow="hidden" h="full">
              <Img alt="algorand foundation" src="./phonesPictures.svg" />
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <Center>
        <Box>
          <Heading color="#2C66B8" fontSize={36} letterSpacing="3px" pb="20px">
            Our Services
          </Heading>
        </Box>
      </Center>
      <Box
        px={{ base: "15rem", md: "15rem", sm: "5rem" }}
        py={{ base: "5rem", md: "3rem", sm: "1rem" }}
      >
        <Center>
          <SimpleGrid
            spacing="10px"
            columns={{ base: 3, md: 2, sm: 1 }}
            display={{ base: "flex", md: "flex", sm: "block" }}
            flexDirection={{ base: "row", md: "row", sm: "column" }}
          >
            <Stack
              px={{ base: "5rem", md: "5rem", sm: "10rem" }}
              bg="#2F3448"
              py={{ base: "2rem", md: "1rem", sm: "1rem" }}
            >
              <HStack>
                <MdSecurity fontSize="5rem" />
                <Text fontSize="2rem">Web3 Security</Text>
              </HStack>
              <Text>
                Certificates are generated and stored on the blockchain, a
                distributed database that provides a secure, yet transparent way
                to make, record and verify any type of transaction
              </Text>
            </Stack>
            <Stack
              px={{ base: "5rem", md: "5rem", sm: "10rem" }}
              bg="#2F3448"
              py={{ base: "2rem", md: "1rem", sm: "1rem" }}
            >
              <HStack>
                <FaSearch fontSize="5rem" />
                <Text fontSize="2rem">Ease of Access</Text>
              </HStack>
              <Text>
                A student or anyone with the certificate ID can search and
                validate it on the Blockchain. Certificates can be printed into
                a PDF format and be sent to anyone.
              </Text>
            </Stack>
          </SimpleGrid>
        </Center>
      </Box>

      <Box
        px={{ base: "15rem", md: "15rem", sm: "5rem" }}
        py={{ base: "5rem", md: "3rem", sm: "1rem" }}
      >
        <Center>
          <SimpleGrid
            spacing="10px"
            columns={{ base: 3, md: 2, sm: 1 }}
            display={{ base: "flex", md: "flex", sm: "block" }}
            flexDirection={{ base: "row", md: "row", sm: "column" }}
          >
            <Stack
              px={{ base: "5rem", md: "5rem", sm: "10rem" }}
              bg="#2F3448"
              py={{ base: "2rem", md: "1rem", sm: "1rem" }}
            >
              <Heading
                lineHeight={1.1}
                fontSize={{ base: "3xl", sm: "4xl", lg: "4xl" }}
              >
                <Text>
                  Start Securely Storing and Verifying Certificates on Africerts
                  today .
                </Text>
              </Heading>
              <Stack
                px={{ base: "5rem", md: "5rem", sm: "10rem" }}
                bg="#2F3448"
                py={{ base: "2rem", md: "1rem", sm: "1rem" }}
              >
                <HStack spacing={{ base: "6", md: "12" }}>
                  <Flex
                    alignItems={"center"}
                    // align= "center"
                    position="absolute"
                    left="36.77%"
                    right="43.68%"
                    // top="90.83%"
                    // bottom="10%"
                    // color="2C66B8"
                  >
                    {/* <Signin
                      darkMode={false}
                      sessionWallet={sessionWallet}
                      accts={accts}
                      connected={connected}
                      updateWallet={updateWallet}
                    /> */}
                  </Flex>
                </HStack>
              </Stack>
            </Stack>
          </SimpleGrid>
        </Center>
      </Box>
      <Footer />
    </>
  );
};

export default Landingpage;
