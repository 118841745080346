import React, { useState } from "react";
import {
  Text,
  FormControl,
  Stack,
  Button,
  FormLabel,
  Input,
  useToast,
  Container,
  AspectRatio,
  Box,
  IconButton,
  Flex,
  Divider,
} from "@chakra-ui/react";
import studentDetails from "../../models/student-details";
import { SessionWallet } from "algorand-session-wallet";
import { NFT } from "../../algorand/nft";
import { BiCloudUpload, BiTrash } from "react-icons/bi";
import uploadInstance from "../../api/uploadAPI";
import { META_URL } from "../../api/api";
import { useForm } from "react-hook-form";
import WalletConnectionNotice from "../connectWalletNotice/walletConnectNotice";
import usePeraWalletConnect from "../../hooks/wallet-connect-hooks/peraWalletConnect";

export type MinterProps = {
  activeConfig: number;
  sw: SessionWallet;
};
type loaderState = {
  loading: boolean;
};

const initLoader: loaderState = {
  loading: false,
};

const University = (props: MinterProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<studentDetails>({
    defaultValues: {
      student_id: "",
      student_name: "",
      course: "",
      grade: "",
      graduation_date: "",
    },
  });

  const [loading, setLoading] = useState(initLoader.loading);
  const [createdId, setCreatedId] = useState<any>(0);
  const [nft, setNFT] = useState<any>();
  const toast = useToast();
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const { isConnectedToPeraWallet, peraAccountAddress, peraWallet } =
    usePeraWalletConnect();

  async function createNft(studentname: string, student_id: string) {
    const cid = META_URL + student_id;
    toast({
      title: "Comfirm",
      position: "top",
      description: "Please Confirm The Transaction On Your Wallet",
      status: "info",
      duration: 2000,
      isClosable: true,
    });
    console.log("NFT CREATING");
    const result = await NFT.create(
      props.sw.wallet,
      props.activeConfig,
      cid,
      studentname
    );

    setNFT(result);
    setCreatedId(result);
    return result;
  }

  const onSubmit = async () => {
    const addresses = props.sw.getDefaultAccount();
    const formData = new FormData();
    formData.append("selectedFile", selectedFile);
    setLoading(true);
    await uploadInstance
      .post(
        "/createStudentsWallets/createStudentsWallets",
        {
          studentsData: selectedFile,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(async (response) => {
        if (response.data.success === true) {
          console.log(response.data.data)
          const arrayAsString = JSON.stringify(response.data.data);
          localStorage.setItem("student_data", arrayAsString);
          toast({
            position: "top",
            title: "Student Wallets created Successfully",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
          setLoading(false);
          reset();
        }
      })
      .catch((error) => {
        const err = error;
        console.log(err) 
        toast({
          position: "top",
          title: " creation of students certificate failed, please try again!",
          description: `${err.response.data.error}`,
          status: "error",
          duration: 3500,
          isClosable: true,
        });
        setLoading(false);
      });

  };

  return (
    <>
      {isConnectedToPeraWallet && peraAccountAddress !== "" ? (
        <Container maxW="6xl">
          <Stack
            px={{ base: "1rem", md: "20rem" }}
            mt="1rem"
            alignContent="center"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack>
                <FormControl mb={0}>
                  <FormLabel htmlFor="studentsData">
                    Create Certificates
                  </FormLabel>
                  <AspectRatio width="1000" height="350" ratio={1}>
                    <Box
                      borderColor="green.300"
                      borderStyle="dashed"
                      borderWidth="2px"
                      rounded="md"
                      role="group"
                    >
                      <Box position="relative" height="100%" width="100%">
                        <Box
                          position="absolute"
                          top="0"
                          left="0"
                          height="100%"
                          width="100%"
                          display="flex"
                          flexDirection="column"
                        >
                          <Stack
                            height="100%"
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justify="center"
                          >
                            <IconButton
                              aria-label=""
                              bg="transparent"
                              _hover={{ bg: "#EAEDF0" }}
                              icon={<BiCloudUpload size="4.5em" />}
                            />
                            <Stack py="6" textAlign="center">
                              <Text mb="3">
                                Drag or Select to Import CSV file.
                              </Text>
                              <Button
                                bg="transparent"
                                color="green.400"
                                fontWeight="semibold"
                                _hover={{
                                  bgGradient:
                                    "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                                  textColor: "white",
                                }}
                              >
                                Browse File
                              </Button>
                              <Stack alignSelf="flex-start" w="full" p={4}>
                                <Text>{selectedFile.length} file uploaded</Text>
                                <Divider />
                                {selectedFile.length !== 0 && (
                                  <Flex justifyContent="space-between">
                                    {fileName}
                                    <Box>
                                      <BiTrash
                                        color="#48BB78"
                                        size="1.8em"
                                        onClick={() => {
                                          setSelectedFile("");
                                          setFileName("");
                                        }}
                                      />
                                    </Box>
                                  </Flex>
                                )}
                              </Stack>
                              {selectedFile && (
                                <Button
                                  mt="2rem"
                                  h="2rem"
                                  // w="full"
                                  color="#ffffff"
                                  bg="#2C66B8"
                                  _hover={{ bg: "#2C66B8" }}
                                  type="submit"
                                  onClick={() => {
                                    handleSubmit(onSubmit);
                                  }}
                                  isLoading={loading}
                                  loadingText="creating student credentials"
                                  _loading={{ bg: "#2C66B8", color: "#ffffff" }}
                                >
                                  Import
                                </Button>
                              )}
                            </Stack>
                          </Stack>
                        </Box>
                        <Input
                          id="studentsData"
                          type="file"
                          height="60%"
                          width="60%"
                          // position="absolute"
                          top="0"
                          left="0"
                          opacity="0"
                          aria-hidden="true"
                          accept=".csv"
                          /* eslint-disable  @typescript-eslint/no-explicit-any */

                          onChange={(event: any) => {
                            setFileName(event.target.files[0].name);
                            return setSelectedFile(event.target.files[0]);
                          }}
                        />
                      </Box>
                    </Box>
                  </AspectRatio>
                </FormControl>
              </Stack>
            </form>
          </Stack>
        </Container>
      ) : (
        <>
          <WalletConnectionNotice />
        </>
      )}
    </>
  );
};

export default University;
