import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Container,
  Heading,
  Link,
  Spinner,
  Center,
} from "@chakra-ui/react";
import instance from "../../api/api";
import axios, { AxiosError } from "axios";
import WalletConnectionNotice from "../connectWalletNotice/walletConnectNotice";
import usePeraWalletConnect from "../../hooks/wallet-connect-hooks/peraWalletConnect";

const DashboardHome = () => {
  const [student, setStudent] = useState<any>([]);
  const { isConnectedToPeraWallet, peraAccountAddress, peraWallet } =
    usePeraWalletConnect();
  useEffect(() => {
    const url = "certData/retrieveCertData/" + peraAccountAddress;
    instance
      .get(url)
      .then((response) => {
          if (response.data) {
            setStudent(response.data);
          }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const err = error.response.data;
          console.log(err);
        }
      });
  });

  return (
    <>
      {isConnectedToPeraWallet && peraAccountAddress !== "" ? (
        <Center>
          <Container maxW="6xl" px={{ base: "1rem", md: "10rem" }} py="5rem">
            <Heading textAlign="center">Certificates Onchain</Heading>
            <TableContainer py="2rem">
              <Table variant="simple" textColor="white">
                <TableCaption>Students Data</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Blockchain Id</Th>
                    <Th>Student Id</Th>
                    <Th>Student Name</Th>
                    <Th>Course</Th>
                    <Th>Graduation Date</Th>
                  </Tr>
                </Thead>

                {student.map((students) => {
                  const walletUrl =
                    "https://testnet.explorer.perawallet.app/assets/" +
                    students.properties.app_id;
                  return (
                    <>
                      <Tbody>
                        <Tr>
                          {students.properties ? (
                            <>
                              <Td>
                                <Link target="_blank" href={walletUrl}>
                                  {students.properties.app_id}{" "}
                                </Link>
                              </Td>
                              <Td>{students.properties.student_id}</Td>
                              <Td>{students.properties.student_name}</Td>
                              <Td>{students.properties.course}</Td>
                              <Td>{students.properties.graduation_date}</Td>
                            </>
                          ) : (
                            <Spinner
                              thickness="4px"
                              speed="0.65s"
                              emptyColor="gray.200"
                              color="blue.500"
                              size="xl"
                            />
                          )}
                        </Tr>
                      </Tbody>
                    </>
                  );
                })}
              </Table>
            </TableContainer>
          </Container>
        </Center>
      ) : (
        <>
          <WalletConnectionNotice />
        </>
      )}
    </>
  );
};
export default DashboardHome;
