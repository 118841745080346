import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Img,
  Stack,
} from "@chakra-ui/react";
import { FiHome, FiSettings } from "react-icons/fi";
import { Link as Navs } from "react-router-dom";
import { HiNewspaper, HiUser, HiXCircle } from "react-icons/hi2";
import { IconType } from "react-icons";
import { ReactText } from "react";
import botho from "../../assets/botho-logo.svg";
import MobileNav from "../../components/dashboard/topnav";

interface LinkItemProps {
  name: string;
  icon: IconType;
  to: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Home", icon: FiHome, to: "/dashboardhome" },
  { name: "Create Wallets", icon: HiNewspaper, to: "/createwallets" },
  {
    name: "Create Certificate",
    icon: HiNewspaper,
    to: "/university",
  },
  { name: "Register Institute", icon: HiUser, to: "/register" },
  // { name: "Revoke Certificate", icon: HiXCircle, to: "#" },
  // { name: "Settings", icon: FiSettings, to: "#" },
];
const LoggeInLinkItems: Array<LinkItemProps> = [
  { name: "Home", icon: FiHome, to: "/dashboardhome" },
  {
    name: "Create Certificate",
    icon: HiNewspaper,
    to: "/university",
  },
];

export default function Dashboard() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      <MobileNav onOpen={onOpen} />
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (
    <Flex
      transition="3s ease"
      bg="blue.900"
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      justifyContent="space-between"
      spacing="1500"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Navs to="/">
          <Stack
            width="10em"
            height="10em"
            align="center"
            justify="center"
            p="10"
            marginTop="20"
            marginBottom="5"
          >
            <Img src={botho} />
          </Stack>
        </Navs>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          to={link.to}
          icon={link.icon}
          onClick={() => {
            window.location.replace(link.to);
          }}
        >
          {link.name}
        </NavItem>
      ))}
    </Flex>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  to: string;
}

const NavItem = ({ icon, to, children, ...rest }: NavItemProps) => {
  return (
    <Navs to={to} style={{ textDecoration: "none" }}>
      <Flex
        marginTop="12"
        align="center"
        p="1"
        mx="1"
        color="white"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Navs>
  );
};
