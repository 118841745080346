import { PeraWalletConnect } from "@perawallet/connect";
import { useCallback, useEffect, useState } from "react";

const peraWallet = new PeraWalletConnect();

export default function usePeraWalletConnect() {
  const [peraAccountAddress, setPeraAccountAddress] = useState<string>("");
  const [isConnectedToPera, setIsConnectedToPera] = useState(false);
  const isConnectedToPeraWallet = !!peraAccountAddress;

  const handleDisconnect = useCallback(() => {
    peraWallet.disconnect();
    setPeraAccountAddress("");
  }, []);

  useEffect(() => {
    // Reconnect to the session when the component is mounted
    peraWallet
      .reconnectSession()
      .then((accounts) => {
        peraWallet.connector?.on("disconnect", handleDisconnect);

        if (accounts.length) {
          setPeraAccountAddress(accounts[0]);
          setIsConnectedToPera(true);
        }
      })
      .catch(() => {
        setIsConnectedToPera(false);
      });
  }, [handleDisconnect]);

  return {
    isConnectedToPeraWallet,
    peraAccountAddress,
    isConnectedToPera,
    handleDisconnect,
    peraWallet,
  };
}
