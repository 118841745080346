import React, { useEffect, useState } from "react";
import "./styles.css";
import "./canterbury.css";
import { Button, Center } from "@chakra-ui/react";
import ReactToPDF from "@kunwarji/react-to-pdf";
import QRCode from "qrcode";
const CertificateTemplate = (props: any) => {
  //tradtional distructuring of the student data props name, app_id, and date created
  const studentId = props.StudentData.properties.student_id;
  const studentName = props.StudentData.properties.student_name;
  const grade = props.StudentData.properties.grade;
  const course = props.StudentData.properties.course;
  const bachelor = props.StudentData.properties.bachelor;
  const type = props.StudentData.properties.type;
  const graduationDate = props.StudentData.properties.graduation_date;
  const dateCreated = props.StudentData.properties.date_created;
  const appId = props.StudentData.properties.app_id;
  const url = `https://testnet.explorer.perawallet.app/assets/${appId}`;
  const [qrcode, setQrcode] = useState("");
  console.log(studentName + " the student name in the props");
  console.log("props here:" + props.StudentData);
  const GenerateQRCodes = () => {
    QRCode.toDataURL(
      url,
      {
        width: 800,
        margin: 2,
        color: {
          dark: "#000000ff",
          light: "#ffffffff",
        },
      },
      (err, url) => {
        if (err) return console.error(err);
        console.log(url);
        setQrcode(url);
      }
    );
  };
  useEffect(() => {
    GenerateQRCodes();
  });
  const refVar = React.createRef<HTMLDivElement>();
  return (
    <>
      <div className="main" ref={refVar}>
        <div className="body-container">
          <div className="main-container">
            <div className="university" >
              <h1 className="university-name" style={{ fontFamily: 'Canterbury' }}> Botho University </h1>
              <div className="logo">
                <svg>
                  <image
                    xlinkHref="https://firebasestorage.googleapis.com/v0/b/botho-logo.appspot.com/o/University%20Logo.svg?alt=media&token=32a0c7f3-d9a3-48ea-b7b6-6c1b98c80aa0"
                    x="0"
                    y="1.5em"
                    width="100%"
                    height="100%"
                  />
                </svg>
              </div>
            </div>
            <p className="cert-reason"> hereby confers upon </p>
            <h3 className="holder-name">{studentName}</h3>
            <p className="achievement">the qualification of</p>
            <h3 className="course-name " style={{ fontFamily: 'Blackchancery' }}>
              {bachelor} ({type}) in {course}
            </h3>
            <p className="classification ">Classification - {grade}</p>
            <p className="conffered-date">Conffered Date - {graduationDate}</p>
            <div className="footer">
              <div className="faculty">
                <p>Mjgoidd</p>
                <hr className="hr" />
                <p>Dean of Faculty</p>
              </div>
              <div className="vice">
                <p>Sheela Ram</p>
                <hr className="hr" />
                <p>Vice-Chancellor</p>
              </div>
              <div className="dean">
                <p>Aravinda Ram</p>
                <hr className="hr" />
                <p>Dean of Academic Services</p>
              </div>
            </div>

            <div className="bottom-footer">
              <div className="left-side">
                <p>Student Id: {studentId}</p>
                <p>Certificate No: GBE27142</p>
              </div>
              <div className="right-side">
                <p>0008200</p>
              </div>
            </div>
          </div>
          <div className="barcode">
            {qrcode && (
              <>
                <img src={qrcode} alt="" />
              </>
            )}
          </div>
        </div>
        <div className="button">
          <Center>
            <ReactToPDF
              element={refVar}
              filename={studentName + ".pdf"}
              width="fit-content"
              height="fit-content"
            >
              {(toPdf) => (
                <Button
                margin-top="2em"
                  w="220px"
                  bg="#2C66B8"
                  color="#ffffff"
                  _hover={{ bg: "blue.900", borderColor: "blue.500" }}
                  variant="solid"
                  borderColor="white"
                  mt="630px"
                  onClick={() => {
                    toPdf();
                  }}
                >
                  Download PDF
                </Button>
              )}
            </ReactToPDF>
          </Center>
        </div>
      </div>
    </>
  );
};
export default CertificateTemplate;
