import { Flex, HStack, Link, Img, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link as Navs } from "react-router-dom";
import Signin from "../../pages/signin/signin";
import { SessionWallet } from "algorand-session-wallet";
import { conf, sessionGetActiveConf } from "../../algorand/config";

export const Header = () => {
  const activeConf = sessionGetActiveConf();
  const sw = new SessionWallet(conf[activeConf].network);
  const [sessionWallet, setSessionWallet] = useState(sw);
  const [accts, setAccounts] = useState(sw.accountList());
  const [connected, setConnected] = useState(sw.connected());

  function updateWallet(sw: SessionWallet) {
    setSessionWallet(sw);
    setAccounts(sw.accountList());
    setConnected(sw.connected());
  }
  return (
    <Flex
      textColor="white"
      w="full"
      py="1rem"
      flexDirection="row"
      alignItems="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      flex={{ base: 1, md: "auto" }}
      ml={{ base: -2 }}
      display={{ base: "flex", md: "flex" }}
      px={{ base: "10rem", md: "10rem" }}
    >
      <Navs to="/">
        <Img src="./logo.svg" />
      </Navs>
      <Flex alignItems="center">
        <HStack color="blue.900" textColor="white" spacing="40px">
          <Link>Home</Link>
          <Link>About</Link>
          <Link>Services</Link>
          <Navs to="/dashboardhome">
            <Button
              w="150px"
              bg="#2C66B8"
              color="#ffffff"
              _hover={{ bg: "#2C66B8" }}
            >
              App
            </Button>
          </Navs>
          <Navs to="/certificate">
            <Button
              w="150px"
              bg="#2C66B8"
              color="#ffffff"
              _hover={{ bg: "blue.700" }}
            >
              Certificate
            </Button>
          </Navs>
        </HStack>
      </Flex>
    </Flex>
  );
};
