import {
  Box,
  Flex,
  HStack,
  Container,
  Heading,
  Center,
  Text,
  Img,
  Circle,
} from "@chakra-ui/react";

const WalletConnectionNotice = () => {
  return (
    <>
      <Box
        position="absolute"
        width="767px"
        height="436px"
        left="440px"
        top="158px"
      >
        <Center>
          <Container
            textColor="white"
            textAlign="center"
            maxW="6xl"
            px={{ base: "1rem", md: "5rem" }}
            py="5rem"
          >
            <Circle
              position="absolute"
              left="35.07%"
              right="41.87%"
              top="-0.92%"
              bottom="61.01%"
              background="#D9D9D9"
            >
              <Img src="./Blockchain-Icon.svg" width="67.9px" height="67.9px" />
            </Circle>
            <Heading
              position="absolute"
              left="26.21%"
              right="33.12%"
              top="50%"
              bottom="39.68%"
              font-family="Open Sans"
              font-style="normal"
              font-weight="800"
              font-size="35px"
              line-height="44px"
              /* or 124% */

              display="flex"
              align-items="center"
            >
              Connect a wallet
            </Heading>
            <Text
              position="absolute"
              left="0%"
              right="0%"
              top="69.5%"
              bottom="20.18%"
            >
              Africerts relies on blockchain technology to create and view
              certificates. Please connect a wallet to get full access
            </Text>

            <HStack spacing={{ base: "0", md: "6" }}>
              <Flex
                alignItems={"center"}
                position="absolute"
                left="36.77%"
                right="43.68%"
                top="90.83%"
                bottom="0%"
                color="2C66B8"
              >
                {/* <Signin /> */}
              </Flex>
            </HStack>
          </Container>
        </Center>
      </Box>
    </>
  );
};

export default WalletConnectionNotice;
