import {
  Text,
  Button,
  Center,
  Container,
  Flex,
  HStack,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Box,
  Divider,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { PeraWalletConnect } from "@perawallet/connect";
import peraWalletLogoIcon from "../../assets/peraWalletLogoIcon.png";
import usePeraWalletConnect from "../../hooks/wallet-connect-hooks/peraWalletConnect";

const peraWallet = new PeraWalletConnect();

const Signin = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [, setPeraConnected] = useState(false);
  const { isConnectedToPeraWallet, handleDisconnect } = usePeraWalletConnect();

  function handleConnectPeraWallet() {
    peraWallet
      .connect()
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setPeraConnected(false);
      });
  }

  function handleDisconnectPeraWallet() {
    handleDisconnect();
    toast({
      position: "top",
      title: "Account disconnected successfully!",
      status: "success",
      duration: 2500,
      isClosable: true,
    });
  }
  return (
    <>
      <Container maxW="3xl" textColor="white">
        <Box w="full" p={3} alignItems="center" justifyContent="center">
          <Box>
            {isConnectedToPeraWallet ? (
              <Button
                bg="#2C66B8"
                borderRadius="full"
                color="#ffffff"
                _hover={{ bg: "#2C66B8" }}
                onClick={() => {
                  handleDisconnectPeraWallet();
                  window.location.reload();
                }}
              >
                Disconnect wallet
              </Button>
            ) : (
              <Button
              bg="#2C66B8"
              borderRadius="full"
              color="#ffffff"
              _hover={{ bg: "#2C66B8" }}
                onClick={onOpen}
              >
                Connect wallet
              </Button>
            )}
          </Box>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bgColor="#162A45" py={4}>
              <ModalHeader color="#fff" textAlign="center">
                Connect Wallet :
              </ModalHeader>
              <ModalCloseButton />
              <Box paddingX={3}>
                <Divider />
              </Box>
              <ModalBody>
                <Stack spacing={3} align="center" py={3}>
                  <Button
                    bg="transparent"
                    w="full"
                    size="xl"
                    p={2}
                    border="1px"
                    borderColor="green.400"
                    fontWeight="semibold"
                    color="#fff"
                    _hover={{
                      bgGradient: "linear(to-r, #49B97A, #7A64CC)",
                    }}
                    leftIcon={
                      <Img
                        src={peraWalletLogoIcon}
                        w="30px"
                        h="30px"
                        alt="pera wallet"
                      />
                    }
                    rightIcon={<FiChevronRight size="30px" color="green" />}
                    onClick={() => {
                      handleConnectPeraWallet();
                      onClose();
                    }}
                  >
                    Pera Wallet
                  </Button>
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </Container>
    </>
  );
};

export default Signin;
