import React, { useEffect, useState } from "react";
import { META_URL } from "../../api/api";
import axios, { AxiosError } from "axios";
import {
  Container,
  Heading,
  Text,
  Button,
  Center,
  HStack,
  Box,
  FormControl,
  Input,
  useToast,
  Stack,
  Flex,
} from "@chakra-ui/react";
import { Header } from "../../components/landingpage/header";
import CertificateTemplate from "../../components/certificate/certificate-template";
import BASE_URL from "../../constants/server";

export const Certificate = () => {
  //states
  const [showCertificate, setshowCertificate] = useState(false); // for displayin cerficate
  const [showSearch, setshowSearch] = useState(true); // for display search bar
  const [studentId, setStudentId] = useState(""); //store user input student id
  const [studentData, setStudentData] = useState(null); //store the student object retrieved
  const [temp, setTemp] = useState(""); //to temporarily store the the input value while user types
  const toast = useToast();

  const handleClick = (event: any) => {
    //handle search button clicked
    setStudentId(temp);
    setshowSearch(false);
  };

  const handleSearchInput = (event: any) => {
    //handle the user inputs
    setTemp(event.target.value);
  };

  useEffect(() => {
    //use effect to handle retrieval from the database
    axios
      .get(`${BASE_URL}/retrieveStudentMetadata/retrieveMetadata/${studentId}`)
      .then((response) => {
        if (response.status === 404) {
          console.log("can not get certificate");
        } else {
          console.log(response.data);
          setStudentData(response.data);
          setshowCertificate((current) => !current);
          setshowSearch(false);
          //toast to show success
          toast({
            description:
              "Congratulations   " + response.data.properties.student_name,
            status: "success",
            duration: 3000,
            position: "top",
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        //checking the status of the response
        if (error.response.status === 404) {
          //toast to signal warning when student deatils not found
          toast({
            description:
              "Student not found. Please check you Student id try again",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
          console.log("cannot get certificate for student id: " + studentId);
          setshowSearch(true);
        } else {
          console.log("student data not available, error: " + error);
        }
      });
  }, [studentId, toast]);

  return (
    <Stack>
      <Header />
      <Heading
        fontWeight={600}
        fontSize="2rem"
        lineHeight={"110%"}
        textAlign="center"
      >
        Student
        <Text mx="15px" as={"span"} color={"#2C66B8"}>
          Certificate
        </Text>
      </Heading>
      {showSearch && (
        <Center py="3rem">
          <HStack display="flex" alignItems="center">
            <FormControl w="600px">
              <Input
                type="text"
                placeholder="Student Id"
                size="lg"
                onChange={handleSearchInput}
              />
            </FormControl>
            <Button
              px="3rem"
              py="1.5rem"
              bg="#2C66B8"
              color="#ffffff"
              _hover={{ bg: "blue.900", borderColor: "blue.500" }}
              variant="solid"
              onClick={handleClick}
            >
              Generate PDF
            </Button>
          </HStack>
        </Center>
      )}
      {showCertificate && (
        <Flex padding="5rem">
          <HStack>
            <Box
              color="black"
              width="fit-content"
              alignItems="center"
              height="fit-content"
              mt="30px"
            >
              <CertificateTemplate StudentData={studentData} />
            </Box>
          </HStack>
        </Flex>
      )}
    </Stack>
  );
};
