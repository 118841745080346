import React from "react";
import MainRoutes from "./routes";
function App() {
  return (
    <>
      <MainRoutes />
    </>
  );
}

export default App;
