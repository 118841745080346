import { Routes, Route, Navigate } from "react-router-dom";
import Homepage from "./pages/Home/homepage";
import Registerprovider from "./pages/Provider-Registration/register-provider";
import University from "./pages/university-dashboard/university";
import Dashboard from "./pages/university-dashboard/Dashboard";
import { conf, sessionGetActiveConf } from "./algorand/config";
import { SessionWallet } from "algorand-session-wallet";
import { useState } from "react";
import DashboardContent from "./pages/university-dashboard/dashboard-content";
import { Certificate } from "./pages/certificate/certificate";
import DashboardHome from "./pages/university-dashboard/dashboard-home";
import CreateWallets from "./pages/university-dashboard/createWallets"
import { Box } from "@chakra-ui/react";

const MainRoutes = () => {
  const darkMode = false;
  const activeConf = sessionGetActiveConf();
  const sw = new SessionWallet(conf[activeConf].network);
  const [sessionWallet, setSessionWallet] = useState(sw);
  const [accts, setAccounts] = useState(sw.accountList());
  const [connected, setConnected] = useState(sw.connected());

  function updateWallet(sw: SessionWallet) {
    setSessionWallet(sw);
    setAccounts(sw.accountList());
    setConnected(sw.connected());
  }

  return (
    <Box
      className="App"
      textColor="white"
      bgGradient="linear(to-r, blue.900, #222)"
      minH="170vh"
    >
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="certificate" element={<Certificate />} />
        <Route path="providerdashboard" element={<Dashboard />} />
        <Route path="/" element={<DashboardContent />}>
          <Route path="/" element={<Navigate replace to="dashboard" />} />
          <Route path="dashboardhome" element={<DashboardHome />} />
          <Route path="register" element={<Registerprovider />} />
          <Route
            path="university"
            element={
              <University activeConfig={activeConf} sw={sessionWallet} />
            }
          />
          <Route
            path="createwallets"
            element={
              <CreateWallets activeConfig={activeConf} sw={sessionWallet} />
            }
          />
        </Route>
      </Routes>
    </Box>
  );
};

export default MainRoutes;
