import axios from "axios";
import BASE_URL from "../constants/server";
const uploadInstance = axios.create({
  baseURL: `${BASE_URL}/`,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});
export default uploadInstance;