import Landingpage from "./Landingpage";
import { Header } from "../../components/landingpage/header";
const Homepage = () => {
  return (
    <>
      <Header />
      <Landingpage />
    </>
  );
};

export default Homepage;
