import axios from "axios";
import BASE_URL from "../constants/server";
const instance = axios.create({
  baseURL: `${BASE_URL}/`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});
export default instance;
export const META_URL =
  `${BASE_URL}/displaygraduatedataroute/displaygraduatedata/`;
