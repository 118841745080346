/* eslint-disable no-useless-escape */
import {
  Text,
  FormControl,
  Input,
  Stack,
  FormLabel,
  Button,
  useToast,
  Center,
  Img,
  Flex,
  Box,
  Heading,
} from "@chakra-ui/react";
import instance from "../../api/api";
import { useEffect, useState } from "react";
import universityDetails from "../../models/institutuion.types";
import axios, { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import botho from "../../assets/botho-logo.svg";
import { useNavigate } from "react-router-dom";
import WalletConnectionNotice from "../connectWalletNotice/walletConnectNotice";
import usePeraWalletConnect from "../../hooks/wallet-connect-hooks/peraWalletConnect";

type loaderState = {
  loading: boolean;
};
type intsituteCheck = {
  exist: boolean;
};

const isExistInitial: intsituteCheck = {
  exist: false,
};
const initLoader: loaderState = {
  loading: false,
};

const Registerprovider = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<universityDetails>({
    defaultValues: {
      universityName: "",
      universityUrl: "",
    },
  });
  const [loading, setLoading] = useState(initLoader.loading);
  const [existInstitude, setExists] = useState(isExistInitial.exist);
  const toast = useToast();
  const { isConnectedToPeraWallet, peraAccountAddress, peraWallet } =
    usePeraWalletConnect();
  useEffect(() => {
    const eixists = "Institution address exists";
    const url = "addressValidation/addressValidation/" + peraAccountAddress; 
    if (peraAccountAddress) {
      instance
        .get(url)
        .then((response) => {
          console.log(response.data);
          if (response.data === eixists) {
            setExists(true);
            return existInstitude;
          } else {
            setExists(false);
            return existInstitude;
          }
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error) && error.response) {
            const err = error.response.data;
            console.log(err);
          }
        });
    }
  });

  const onSubmit = async (data: universityDetails) => {
    if (peraAccountAddress) {
      setLoading(true);
      await instance
        .post("/addProviderData/addProviderData", {
          institution_name: data.universityName,
          peraAccountAddress,
          institution_url: data.universityUrl,
        })
        .then((res: { data: universityDetails }) => {
          if (res) {
            toast({
              title: "Account created.",
              description: "You have successfully registered your Intsitution.",
              position: "top",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            setLoading(false);
            reset();
          }
        })
        .catch((error: Error | AxiosError) => {
          setLoading(false);
          if (axios.isAxiosError(error) && error.response) {
            const err = error.response.data;
            toast({
              position: "top",
              title: "Unable To Register Institution",
              description: `${err}`,
              status: "error",
              duration: 3500,
              isClosable: true,
            });
          }
          reset();
          console.log("error message");
          console.log(error);
        });
    }
  };
  return (
    <>
      {isConnectedToPeraWallet && peraAccountAddress !== "" ? (
        <Center>
          {existInstitude ? (
            <>
              <Exists />
            </>
          ) : (
            <Flex
              as={Box}
              alignContent="center"
              gap="14"
              py={{ base: "10rem", md: "15rem" }}
              flexDirection={{ base: "column", md: "row", sm: "column" }}
            >
              <Box mt="10">
                <Img
                  src={botho}
                  w={150}
                  display={{ base: "none", md: "flex", sm: "none" }}
                />
              </Box>

              <Stack mx={"1rem"} w="auto">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Text
                    fontSize="30px"
                    lineHeight="36px"
                    fontWeight="600"
                    fontStyle="normal"
                  >
                    Register Your Institution
                  </Text>
                  <Text
                    fontSize="18px"
                    lineHeight="28px"
                    fontStyle="normal"
                    fontWeight="400"
                    color="#8692A6"
                  >
                    Please make sure that you are connected before signup
                  </Text>
                  <FormControl mt="2rem">
                    <FormLabel>University Name</FormLabel>
                    <Input
                      type="text"
                      w="full"
                      h="3rem"
                      {...register("universityName", {
                        required: true,
                        maxLength: 50,
                      })}
                    />
                    {errors.universityName &&
                      errors.universityName.type === "required" && (
                        <Text as={"span"} color="red" fontSize="15px">
                          university name is required{" "}
                        </Text>
                      )}
                  </FormControl>
                  <FormControl>
                    <FormLabel>University Url</FormLabel>
                    <Input
                      type="url"
                      w="full"
                      h="3rem"
                      {...register("universityUrl", {
                        required: true,
                        pattern: {
                          value:
                            /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
                          message: "please enter a valid url",
                        },
                      })}
                    />
                    {errors.universityUrl &&
                      errors.universityUrl.type === "required" && (
                        <Text as={"span"} color="red" fontSize="15px">
                          institution url required
                        </Text>
                      )}
                    {errors.universityUrl && (
                      <Text as={"span"} color="red" fontSize="15px">
                        {errors.universityUrl.message}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl>
                    <Button
                      h="3rem"
                      w="full"
                      color="#ffffff"
                      bg="#2C66B8"
                      _hover={{ bg: "#2C66B8" }}
                      mt="1rem"
                      type="submit"
                      isLoading={loading}
                      loadingText="Submitting"
                    >
                      Register
                    </Button>
                  </FormControl>
                </form>
              </Stack>
            </Flex>
          )}
        </Center>
      ) : (
        <>
          <WalletConnectionNotice />
        </>
      )}
    </>
  );
};

export default Registerprovider;

const Exists = () => {
  return (
    <Center py="20rem" px="20rem">
      <Heading>University Already Registered</Heading>
    </Center>
  );
};
