import React from "react";
import { Box, Flex, Text, Link, Img, HStack } from "@chakra-ui/react";
import { PhoneIcon, EmailIcon } from "@chakra-ui/icons";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";

export const Footer = () => {
  return (
    <Flex
      as="footer"
      align="center"
      justify="space-between"
      // padding="1rem"
      backgroundColor="gray.900"
      color="white"
    >
      <Box
        bg="blue.900"
        w="full"
        h="200px"
        justifyContent="space-between"
        alignItems="center"
        // display={{base:"column", md: "flex"}}
      >
        <Flex ml="20px">
          <Box mt="10px" color="white">
            <Img src="./logo.svg" />
          </Box>
          <Box ml="400px">
            <Text fontSize="1em" mt="10px" color="white">
              About
            </Text>
            <Text fontSize="1em" mt="10px" color="white">
              Services
            </Text>
            <Text fontSize="1em" mt="10px" color="white">
              Learn More
            </Text>
          </Box>
          <Box fontSize="1em" ml="250px" mt="10px" color="white">
            <Text mt="10px" color="white">
              <PhoneIcon mr="3px" w={5} h={5} color="white.500" /> +1 (650)
              575-1094
            </Text>
            <Text color="white">
              <EmailIcon mt="5px" mr="9px" w={5} h={5} color="white" />
              africerts.com
            </Text>

            <Flex color="white" flexDirection="row">
              <HStack color="red" textColor="white" spacing="40px">
                <Link>
                  <FaFacebookSquare />
                </Link>
                <Link>
                  <FaInstagram />
                </Link>
                <Link>
                  <FaLinkedin />
                </Link>
                <Link>
                  <FaTwitter />
                </Link>
              </HStack>
            </Flex>
          </Box>
        </Flex>
        <Text color="white" ml="550px" fontFamily="Inter" mt="5px">
          {" "}
          POWERED BY AFRICA CODE FOUNDRY.
        </Text>
      </Box>
    </Flex>
  );
};
